<template>
  <div
    class="max-w-[500px] lg:max-w-[600px] grow p-4 my-auto flex-col justify-center items-center"
  >
    <svg
      width="auto"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="136 190 618 789.33"
      style="enable-background: new 0 0 841.89 1190.55"
      xml:space="preserve"
    >
      <g
        id="Altmark"
        @click="emitRegion('Altmark')"
        class="cursor-pointer tik_map_region"
        :class="[selectedRegion == 'Altmark' ? 'selectedRegion' : '']"
      >
        <path
          class="tik_map_st1"
          d="M247.42,402.99l-4.01,2.86l-4.58-0.29l-2-3.72l-3.44-2.86l-1.72-3.15l-2.29-2.86l-1.72-1.72l-1.15-2.86
		l-2.58-0.57l-1.43-7.45l2.86-2.29l-1.15-2.86l0.57-3.72l3.44-2.29l2-3.15c0,0,2.29-0.57,2.29-1.43c0-0.86-0.57-4.3-0.57-4.3
		l-4.01-0.86l-2.58,3.44l-3.44,2.29l-3.15-1.15c0,0-0.86-4.87-1.43-5.73c-0.57-0.86-3.72-3.72-3.72-3.72l-3.44-5.15l-0.86-4.3
		l-6.87-6.59l-0.57-3.72l-2-2.58l1.15-3.72l-2.58-1.72l-1.72-1.15l-6.01,0.57c0,0,0-4.58,0-5.44c0-0.86-1.72-6.3-1.72-6.3
		l-4.01-2.29l-0.29-5.15l-4.3-3.72l2-4.58l-1.72-8.02l0.57-3.72l2.29-2.29l-0.86-5.15l2-1.43l8.3,2.29l4.58-2l5.15-2.58l6.3,0.29
		l1.43,2.86l2.58-2h8.88l0.57-4.3l2.86-0.86v-4.58l6.59-1.15l-0.57-5.44l1.15-3.72l-1.72-2.29l5.73-0.86l6.01-1.43l5.44,2.58
		l6.59-2.29l2.58,0.86l0.29,4.87l2.86,1.15l5.15-2.58h5.73l1.72-3.15c0,0,3.44,1.15,4.3,1.43c0.86,0.29,6.01,0.86,6.01,0.86
		l3.44,2.29l-0.86,2l3.15,2.58l6.3-0.29l1.72-2.86l4.87,1.43l1.43,2.58l3.44-0.57l3.72-3.15l4.58-1.72l3.15-4.58l6.01-0.57
		l3.15-5.15l6.01-1.72l0.29-2.86l4.3-3.15h6.87l-1.72-5.15l-0.86-6.87l3.44-2.58l-0.86-7.45l0.57-2.86h5.15l4.3,1.15l2-0.86
		l3.44-5.73l4.58-4.01l2-5.44l5.44-0.29l2.86,1.15l0.86,5.15l-3.15,3.44l1.43,3.15l2,0.86l2.29-1.15l3.44-0.29l3.15,0.57l0.86,3.15
		l-2.29,2.29l2.58,1.72l0.29,3.72l3.44-0.86h2l1.15-2.86c0,0,1.43-1.43,2.29-1.43c0.86,0,5.15,0.29,5.15,0.29l3.72,2.58
		c0,0-2,1.72-1.15,1.72s2.29,1.72,2.29,1.72l2,2.29l0.57,3.15c0,0,1.72,1.15,2.58,1.15c0.86,0,3.44-0.57,3.44-0.57l3.44,0.29h3.72
		l1.43,3.15l-1.15,3.44l-0.86,2.29l-2.29,0.29l-1.43,1.43l0.86,4.87l2.58,0.29l5.15-0.29l3.15,0.86l1.72,2l3.72,3.72
		c0,0,2.29,0,3.15,0c0.86,0,6.59,0.29,6.59,0.29l2.58,0.29l0.86,2.29l2.29,0.86l2.86,1.43l2-0.72l4.08-0.43c0,0,0.43-1.5,0.43-2.15
		s1.5-2.15,1.5-2.15s5.58-0.43,6.23,0c0.64,0.43,6.01-0.86,6.01-0.86l2.36,2.15c0,0,1.5,2.36,2.58,2.36c1.07,0,3.65-0.86,3.65-0.86
		l1.93-2.36l3.22,1.29l0.43,4.3l0.43,3.87l1.93,1.07l4.3-1.07l2.36-3.22l2.36-1.5c0,0,0.64-2.79,1.29-2.58
		c0.64,0.21,2.79,3.01,2.79,3.01l2.79,1.29l2.79,0.21l1.5,3.01l-0.43,3.22c0,0,1.5,2.58,1.5,3.22c0,0.64,1.72,3.65,1.72,3.65
		l0.21,4.51l1.72,1.72c0,0,2.15,3.01,1.72,4.08c-0.43,1.07-1.5,1.93-1.5,2.79c0,0.86-1.07,2.58-1.07,2.58h-3.87l-0.43,4.08
		l-2.36,1.5l-1.07,2.36v3.44l2.58,1.5l0.64,3.65l-1.07,3.01l-3.01,1.72l-0.64,3.44l2.15,1.72l1.72,3.22c0,0,1.29-0.21,1.29,0.86
		s0.21,4.51,0.21,4.51l2.58,1.29l0.43,2.36l-0.43,12.24l1.07,6.01l-1.07,2.79l-2.79,0.21l-1.29,3.87l-3.22-0.43l-3.44-0.64
		l-1.72-1.07l-0.43,2.36l-0.21,4.94l1.72,4.51l-0.43,2.79l1.72,2.15l-0.64,2.58l-2.15,2.79l-2.15,1.5l0.43,3.22l-0.86,4.51
		l2.58,0.86l2.36-1.29l2.36,2.36l-0.64,3.87l-2.36,5.66h-10.31l-4.3-0.86l-3.15-8.02l-4.58-0.57c0,0-3.44,4.01-4.3,3.72
		c-0.86-0.29-4.58-0.29-4.58-0.29l-2.86,3.15l-3.72-0.86l-2.58-3.15l1.15-7.16l-1.15-3.72l-1.43,3.15l-2.29,6.87l-2.58,2.29
		l-1.43,4.87l2.86,2.29l3.15,2.58V405l3.72,2.86l0.86,13.17l-2.29,1.72l-0.86,3.44l-2.58,2v6.01h-2.86l-5.15,2.86l-0.86,6.01
		l-0.57,2.86l-4.87,0.57l-1.72,5.73h-1.72l-2,3.15l-2,4.3l-4.01,0.86l-1.43-1.43l1.15-6.59l-2-0.57l-3.44-2.29l-0.86-4.01l2.58-4.01
		l-0.86-6.87l-0.86-3.15h-5.15l-1.43-2l-4.01-0.57l-0.57,3.15v4.58l-4.87,0.86c0,0-4.3-2.29-5.15-2.29s-3.72-1.43-3.72-1.43
		l0.57-6.87l-1.43-1.43l-2.29-5.15l-1.43-1.43l-1.15-4.01h-4.58l-5.15,0.86l-1.72-3.44l-4.01-0.57l-3.44,2.86l-4.87,1.15l-3.15,6.59
		l0.86,10.02l-4.01,1.43l-5.73,3.72l-5.15-0.29l-4.01,3.15l-6.59,3.15l-2.86-1.43v-5.44l-2-2.58l-2.58-0.86l-3.44-2.29l-6.87-0.86
		l-2.58-2l-3.44,2.29l-6.59,0.57l-2.86-2l-0.86-3.72c0,0-3.15-0.29-4.01,0c-0.86,0.29-2.86,2.86-2.86,2.86l-7.16-0.29l-8.02-1.15
		l-4.58,1.43l-12.03,0.29l-4.3-3.72l-4.58-3.44h-2.86l-2.86-7.16l-2.58-1.72l0.29-6.59l-2.58-3.44L247.42,402.99l-4.01,2.86
		l-4.58-0.29"
        />
        <text
          transform="matrix(1 0 0 1 327.5727 343.0052)"
          class="tik_map_region_text font-light font-larsseit"
        >
          ALTMARK
        </text>
      </g>
      <g
        id="Magdeburg-Elbe-Börde-Heide"
        @click="emitRegion('Magdeburg-Elbe-Börde-Heide')"
        :class="[
          selectedRegion == 'Magdeburg-Elbe-Börde-Heide'
            ? 'selectedRegion'
            : '',
        ]"
        class="cursor-pointer tik_map_region"
      >
        <path
          class="tik_map_st3"
          d="M251.66,402.5l-4.3,0.57l-4.01,2.86l-4.58-0.29l-8.42,0.53l-4.53,2.88l-0.41,7.42l-1.85,3.71l3.71,1.85
		l1.24,4.53l7.21,7.42l8.24,8.65l1.44,3.5l5.97,0.21l2.47,2.88l2.88,3.3l-1.65,1.44l-0.21,5.15l-3.09,0.41l-2.27,3.09l-8.86-0.62
		l-3.3,2.27l3.5,4.12l5.97,4.12l1.65,4.12l-1.24,3.3l-3.5,2.06l0.62,4.53l4.33,1.24l1.85,3.71l2.47,1.65l1.44,7.62l4.94-0.21
		l0.41,5.77l-2.88,1.03l-0.62,2.47l-4.12,0.21l-5.36,1.24l-0.62,2.68l-2.68,1.65c0,0,0,7.21,0.62,7.42
		c0.62,0.21,2.68,0.41,2.68,0.41l1.44,2.88l8.24,0.62l-2.68,3.5l-0.62,5.77l-1.85,1.24l-0.82,2.47l-3.71,1.65l-2.06,4.74l-3.91,0.62
		l-3.5,2.88l-5.56,1.44l-2.68,1.65l0.41,2.88l2.27,2.06l3.71,0.41l1.24,3.09l-1.44,2.06l-0.82,4.07l5.73,2.29h4.01l2.86,3.72
		l4.58,0.29l2,1.43l4.58-0.86l2.58,4.58l3.15,1.43l-0.29,4.87l2.86,1.43l1.72,2.29l10.02-0.29l1.43,2l-0.86,9.45l4.01,0.86
		l3.15,0.86l-2,5.15l-3.72,4.01c0,0,2.58,0.29,2.58,1.15s1.43,5.15,1.43,5.15l4.3-1.43l3.15-1.72l3.15,2.29c0,0,2,3.72,2,4.58
		c0,0.86,2,3.44,2,3.44l4.87-0.29l2,1.43l3.72-2l0.86-3.72l3.15,0.57c0,0,0.86,2.86,1.72,3.15s4.87-0.29,4.87-0.29l1.43,5.15
		l-1.72,4.87l1.43,5.73l-0.86,5.44l-4.01,2.58l-2,3.15l-4.01,0.29l-2.29-1.72l-3.44,0.57l-0.29,5.15l-2.58,3.44l2.29,2l3.15,1.43
		l0.29,4.87l1.15,3.44l4.3,2l2.29,4.3c0,0,2,0.29,2.86,0.57c0.86,0.29,5.15-5.73,5.15-5.73l3.72-2c0,0,3.15,1.72,4.3,1.72
		s4.3,0,4.3,0l0.86,3.44l2.86,0.29v4.58l-1.43,4.58l2,4.3l2.86-0.57c0,0,0.86,2.58,1.72,2.86c0.86,0.29,4.01,0.29,4.01,0.29l3.15-2
		l4.01,0.86l5.15,0.57l2.86,2l-0.29,2.86l2.29,3.15l-1.43,2.58l5.44-0.57l1.15,0.57l7.73-1.72l1.15-2.86l6.87,0.57l0.57,3.72
		l-1.15,4.01l-1.43,1.72l2.29,1.72l2.29,2.86l2.86,1.43c0,0,4.01-0.29,4.87-0.29s4.87-0.29,4.87-0.29l3.44,1.15l3.15,3.72l3.44,1.72
		l1.72,2.58l3.33,0.75l0.97,0.11l0.57-5.44l2.29-1.43l0.29-2.29l4.01-0.29l4.3,3.15c0,0,2.29-2,3.15-2.29s3.15-0.57,3.15-0.57
		l-1.15-3.72l4.3,0.29l3.44,2.58l1.43-8.59l0.63-1.27l-2.97-2.12l-1.55-2.4v-7.91c0,0,2.26-2.82,2.26-3.39c0-0.56-0.85-7.2-0.85-7.2
		l6.92-5.22l1.84-3.67l-5.22-2.54l-2.82-2.4l-3.11-5.65l0.71-3.81l5.65-0.99c0,0,1.27-4.38,0.99-4.94s-2.54-2.97-3.11-3.53
		c-0.56-0.56-3.39-4.38-3.39-4.38l3.53-5.08l2.4-6.21l3.11,0.28l3.67-1.55l3.25-3.81c0,0,3.95,0.14,4.66,0.42
		c0.71,0.28,2.97,3.11,2.97,3.11s1.85,1.25,2.71,1.25s3.87-0.86,3.87-0.86l-1.29-2.36h5.37l1.29-2.58l-1.93-4.51
		c0,0-6.87-4.3-7.3-5.15c-0.43-0.86-1.29-3.44-1.72-4.08c-0.43-0.64-5.37-4.51-5.37-4.51l-8.38-8.16l-5.8-3.01l-0.43-4.3l-1.29-1.72
		l2.15-5.58l2.58-1.93l6.23,0.43l3.01-1.07l2.79-4.08l2.36-1.5l-0.21-2.36l4.08-0.21l1.93-2.36l4.08-0.64l1.93-2.15l0.43-4.3
		l3.87-1.5c0,0,0-1.29,0-2.15c0-0.86,2.15-1.07,2.15-1.07l3.01,1.5l2.58-4.3l1.93-2.58l3.65,2.15l7.73-0.64l5.58-1.07l1.07-2.58
		l5.37-0.43l0.86-1.93l3.65-0.64v-3.22l0.86-2.36l9.88-0.86l4.94-0.64l-1.17-1.59l-0.82-5.15l-2.47-5.15c0,0-3.5-1.65-3.5-2.27
		c0-0.62-2.27-5.97-2.27-5.97l-4.94-3.5l2.06-3.3l4.12-1.44l-0.41-4.12l0.41-6.39l3.91-0.41l4.53-2.88l2.47-3.09l-3.5-0.82
		l-2.47-4.33c0,0-4.33-2.88-4.94-3.09c-0.62-0.21,0.62-8.86,0.62-8.86l1.85-6.39l1.85-6.8l4.33-5.56l2.88-6.59
		c0,0-1.44-3.71-2.06-3.91c-0.62-0.21-3.3-3.5-3.3-3.5l2.27-2.27c0,0,1.03-7.21,1.03-7.83c0-0.62-0.21-6.59-0.21-6.59l-5.36-1.44
		l1.85-1.65l0.41-4.53h3.91l-0.62-7.62l3.3-1.03l1.24-6.8l-1.03-4.53l1.85-4.74l-5.36,1.24c0,0-8.03-0.21-8.65-0.21
		c-0.62,0-1.03-1.85-1.03-2.47s-0.21-4.74-0.21-4.74l-1.44-2.88l-3.5-1.03l-1.03,2.06l-3.3,2.47l-0.21,5.15l-1.85,0.62l-7.21,0.21
		l-2.54-2.88h-10.31l-4.3-0.86l-3.15-8.02l-4.58-0.57c0,0-3.44,4.01-4.3,3.72s-4.58-0.29-4.58-0.29l-2.86,3.15l-3.72-0.86
		l-2.58-3.15l1.15-7.16l-1.15-3.72l-1.43,3.15l-2.29,6.87l-2.58,2.29l-1.43,4.87l2.86,2.29l3.15,2.58v7.16l3.72,2.86l0.86,13.17
		l-2.29,1.72l-0.86,3.44l-2.58,2v6.01h-2.86l-5.15,2.86l-0.86,6.01l-0.57,2.86l-4.87,0.57l-1.72,5.73h-1.72l-2,3.15l-2,4.3
		l-4.01,0.86l-1.43-1.43l1.15-6.59l-2-0.57l-3.44-2.29l-0.86-4.01l2.58-4.01l-0.86-6.87l-0.86-3.15h-5.15l-1.43-2l-4.01-0.57
		l-0.57,3.15v4.58l-4.87,0.86c0,0-4.3-2.29-5.15-2.29c-0.86,0-3.72-1.43-3.72-1.43l0.57-6.87l-1.43-1.43l-2.29-5.15l-1.43-1.43
		l-1.15-4.01h-4.58l-5.15,0.86l-1.72-3.44l-4.01-0.57l-3.44,2.86l-4.87,1.15l-3.15,6.59l0.86,10.02l-4.01,1.43l-5.73,3.72
		l-5.15-0.29l-4.01,3.15l-6.59,3.15L341,442.3v-5.44l-2-2.58l-2.58-0.86l-3.44-2.29l-6.87-0.86l-2.58-2l-3.44,2.29l-6.59,0.57
		l-2.86-2l-0.86-3.72c0,0-3.15-0.29-4.01,0c-0.86,0.29-2.86,2.86-2.86,2.86l-7.16-0.29l-8.02-1.15l-4.58,1.43l-12.03,0.29l-4.3-3.72
		l-4.58-3.44h-2.86l-2.86-7.16l-2.58-1.72l0.29-6.59L251.66,402.5l-4.3,0.57l-4.01,2.86l-4.58-0.29"
        />
        <text
          transform="matrix(1 0 0 1 327.7924 526.1053)"
          class="tik_map_region_text"
        >
          <tspan x="0" y="0" class="font-light font-larsseit">
            MAGDEBURG -
          </tspan>
          <tspan x="1.08" y="18.94" class="font-light font-larsseit">
            ELBE - BÖRDE -
          </tspan>
          <tspan x="32.46" y="37.88" class="font-light font-larsseit">
            HEIDE
          </tspan>
        </text>
      </g>
      <g
        id="Harz"
        @click="emitRegion('Harz')"
        :class="[selectedRegion == 'Harz' ? 'selectedRegion' : '']"
        class="cursor-pointer tik_map_region"
      >
        <path
          class="tik_map_st5"
          d="M234.62,567.37l5.73,2.29h4.01l2.86,3.72l4.58,0.29l2,1.43l4.58-0.86l2.58,4.58l3.15,1.43l-0.29,4.87
		l2.86,1.43l1.72,2.29l10.02-0.29l1.43,2l-0.86,9.45l4.01,0.86l3.15,0.86l-2,5.15l-3.72,4.01c0,0,2.58,0.29,2.58,1.15
		s1.43,5.15,1.43,5.15l4.3-1.43l3.15-1.72l3.15,2.29c0,0,2,3.72,2,4.58c0,0.86,2,3.44,2,3.44l4.87-0.29l2,1.43l3.72-2l0.86-3.72
		l3.15,0.57c0,0,0.86,2.86,1.72,3.15s4.87-0.29,4.87-0.29l1.43,5.15l-1.72,4.87l1.43,5.73l-0.86,5.44l-4.01,2.58l-2,3.15l-4.01,0.29
		l-2.29-1.72l-3.44,0.57l-0.29,5.15l-2.58,3.44l2.29,2l3.15,1.43l0.29,4.87l1.15,3.44l4.3,2l2.29,4.3c0,0,2,0.29,2.86,0.57
		c0.86,0.29,5.15-5.73,5.15-5.73l3.72-2c0,0,3.15,1.72,4.3,1.72c1.15,0,4.3,0,4.3,0l0.86,3.44l2.86,0.29v4.58l-1.43,4.58l2,4.3
		l2.86-0.57c0,0,0.86,2.58,1.72,2.86c0.86,0.29,4.01,0.29,4.01,0.29l3.15-2l4.01,0.86l5.15,0.57l2.86,2l-0.29,2.86l2.29,3.15
		l-1.43,2.58l5.44-0.57l1.15,0.57l7.73-1.72l1.15-2.86l6.87,0.57l0.57,3.72l-1.15,4.01l-1.43,1.72l2.29,1.72l2.29,2.86l2.86,1.43
		c0,0,4.01-0.29,4.87-0.29s4.87-0.29,4.87-0.29l3.44,1.15l3.15,3.72l3.44,1.72l1.72,2.58l4.02,0.83l0.42,0.75l1.93,1.72v2.79
		l-0.43,1.5l-3.22,0.21l-1.5,2.15l-0.64,4.3l-2.36,0.86l-2.15,1.5l-1.5,5.15l-2.58,1.07v2.58l3.87,1.5l1.72,3.22l2.36,3.01
		l-0.64,3.01l-2.15,4.51l-1.93,3.87l2.36,1.07l2.58,3.87c0,0,5.15,3.87,5.8,4.51c0.64,0.64,5.15,4.3,5.8,4.94
		c0.64,0.64,0.64,4.3,0.64,4.94c0,0.64-1.72,3.65-1.72,3.65l-4.3,0.43l-2.15,4.3l-3.01,1.5l-4.94,1.72c0,0-3.22-1.72-3.44-2.36
		c-0.21-0.64-2.15-6.66-2.15-6.66l-3.87,0.21l-3.01-2.36l-5.37-0.43l-2.79,2.36l-1.29,1.5l-2.36,2.15l-3.87,0.64h-6.01l-2.36,3.01
		l-2.79,1.07l-0.64,4.51l-3.87,1.07l-2.36,2.36l-0.43,3.65l-2.58,1.93l-0.43,1.93l-2.79,1.07l-0.21,3.87l-2.36,0.43l-1.93,3.44
		l-3.22,0.21l-2.15,5.37l-2.58,4.08l-3.02-2.23l-2.18-5.02l0.5-6.02l-0.84-2.51h-10.38l-1.84-2.34l-1.67-3.01l-2.68-2.01l-4.18,1.67
		l-5.86-0.5l-3.18,0.67l-2.01,2.34l-3.35-2.34l-2.68-3.18l-4.02,0.5l-2.84,3.51l-8.7-1l-3.68,0.33l-3.68-0.84l-2.18-2.68l-5.19-0.84
		l-2.18-2.68l-5.36-0.67l-4.52,0.5l-3.68,3.35l-4.35,0.33l-2.84-2.68l-3.51-0.5l-3.68-2.68l0.17-9.54l-0.84-4.52
		c0,0,1.17-5.36,1.17-5.86s-4.35-4.85-4.35-4.85s-3.01-3.01-3.01-3.51s-1.67-2.84-1.67-2.84v-4.02c0,0,2.34-0.84,2.34-1.34
		c0-0.5-0.17-4.02-0.17-4.02l-3.18-1.67l-5.02-0.33l-1.17-1.34l-0.33-6.02l-3.35-3.01l-0.67-2.18l1.84-3.18l4.18,0.33l5.19-0.33
		l-0.33-2.84l0.84-2.51l-3.68-2.01l-1.34-2.68l-5.86,0.5l-3.51-0.67l-3.85-4.18l-2.84-0.84l-3.01,2.51l-2.51-0.17l-2.34-3.35
		l-4.35-2.01l-9.04-0.5l-6.69,0.33l-5.69,1.34l-2.68-2.18l-0.84-5.69l-3.01-1.51l-1.84-6.86l1.67-3.18l-1-6.02l-3.85-2.68
		l-1.17-3.18l-3.01-2.51l-1-7.87l-3.18-1.17l-1.51-2.34l-3.68-0.84l-2.51-4.02l-1-6.86l0.17-18.74l1.51-1.51l2.84-0.5l1-2.84
		l3.68-2.01l2.68-3.35l1.34-3.68h3.01l-0.17-6.19l-3.18-3.51l-2.01-3.51l-4.02-0.17l-1.17-6.02l3.51-2.18l4.69-3.51l-1.17-1.67
		l-4.52-2.18l-2.84-3.35l-3.51-1l-3.01-3.85l-3.51-2.51l-0.33-3.18l2.51-3.51l4.85,0.33l6.19,1l1.51-3.68l3.18-0.84l2.18-2.34v-5.69
		l6.69-2.34c0,0,2.51-1.34,3.35-1.17s12.05,1.34,12.05,1.34l8.37-1l13.22-0.17c0,0,4.35-3.01,4.85-3.18s4.18-2.18,5.19-2.01
		C219.66,567.53,234.62,567.37,234.62,567.37z"
        />
        <text
          transform="matrix(1 0 0 1 244.9086 697.4378)"
          class="tik_map_region_text font-light font-larsseit"
        >
          HARZ
        </text>
      </g>
      <g
        id="Anhalt-Dessau-Wittenberg"
        @click="emitRegion('Anhalt-Dessau-Wittenberg')"
        :class="[
          selectedRegion == 'Anhalt-Dessau-Wittenberg' ? 'selectedRegion' : '',
        ]"
        class="cursor-pointer tik_map_region"
      >
        <path
          class="tik_map_st6"
          d="M531.43,540.16l-4.94,0.64l-9.88,0.86l-0.86,2.36v3.22l-3.65,0.64l-0.86,1.93l-5.37,0.43l-1.07,2.58
		l-5.58,1.07l-7.73,0.64l-3.65-2.15l-1.93,2.58l-2.58,4.3l-3.01-1.5c0,0-2.15,0.21-2.15,1.07c0,0.86,0,2.15,0,2.15l-3.87,1.5
		l-0.43,4.3l-1.93,2.15l-4.08,0.64l-1.93,2.36l-4.08,0.21l0.21,2.36l-2.36,1.5l-2.79,4.08l-3.01,1.07l-6.23-0.43l-2.58,1.93
		l-2.15,5.58l1.29,1.72l0.43,4.3l5.8,3.01l8.38,8.16c0,0,4.94,3.87,5.37,4.51c0.43,0.64,1.29,3.22,1.72,4.08s7.3,5.15,7.3,5.15
		l1.93,4.51l-1.29,2.58h-5.37l1.29,2.36c0,0-3.01,0.86-3.87,0.86s-2.71-1.25-2.71-1.25s-2.26-2.82-2.97-3.11s-4.66-0.42-4.66-0.42
		l-3.25,3.81l-3.67,1.55l-3.11-0.28l-2.4,6.21l-3.53,5.08c0,0,2.82,3.81,3.39,4.38c0.56,0.56,2.82,2.97,3.11,3.53
		c0.28,0.56-0.99,4.94-0.99,4.94l-5.65,0.99l-0.71,3.81l3.11,5.65l2.82,2.4l5.22,2.54l-1.84,3.67l-6.92,5.22c0,0,0.85,6.64,0.85,7.2
		c0,0.56-2.26,3.39-2.26,3.39v7.91l1.55,2.4l2.97,2.12l1.02,1.06l1.72,0.86l1.29,2.15l3.01,0.86l1.93,3.01l3.65,2.79l7.3-0.64
		l3.01,1.93c0,0,3.44-1.5,4.08-1.5c0.64,0,12.89,0,12.89,0l0.64,4.08l-1.07,3.22l2.79,2.58l0.64,3.01l-3.01,5.15l2.15,2.15
		c0,0,3.44,1.93,4.08,1.93c0.64,0,7.3,0,7.3,0s13.96-0.86,14.6-0.86c0.64,0,3.22,1.07,3.22,1.07l1.07,2.36l-1.5,6.23l1.29,3.87
		l3.01,0.64l3.65,3.22l2.59-0.78l0.57-2.63l3.09-1.26l1.94-1.94l0.23-3.89l13.72-0.11c0,0,2.63-1.6,2.74-2.06s2.86-3.77,2.86-3.77
		c0.34-0.34,2.4-0.69,2.4-0.69s1.37-2.74,1.72-2.86c0.34-0.11,6.17-0.69,6.63-0.57c0.46,0.11,11.09,0.57,11.09,0.57
		s0.91-2.52,0.91-2.86s1.83-2.52,1.83-2.52s1.26-2.97,1.72-3.2c0.46-0.23,3.66-0.57,5.03-0.57c1.37,0,4.12,2.4,4.69,2.63
		c0.57,0.23,2.06,0.11,3.2-0.46c1.14-0.57,2.74-2.52,3.77-2.52c1.03,0,6.63,0.23,7.32-0.11s5.26-2.63,5.49-3.09
		c0.23-0.46,2.74-3.09,3.2-3.2s5.95-0.69,5.95-0.69l2.86,2.4c0,0,2.29-1.14,3.09-1.14s3.09-1.03,4-1.49
		c0.91-0.46,0.91-1.83,1.6-3.09c0.69-1.26,1.37-2.52,1.72-3.43c0.34-0.91-0.23-4.57,0.23-5.03s5.26-3.77,5.26-3.77s5.03-0.11,6.52,0
		c1.49,0.11,3.77,1.94,4.34,2.52c0.57,0.57,3.32,1.83,4.34,1.83c1.03,0,4.12-0.46,4.57-0.69c0.46-0.23,1.6-3.43,1.6-3.43
		s0.34-1.94,1.6-2.06s3.32-1.03,3.54-1.37c0.23-0.34,0.34-1.94,0.8-2.06c0.46-0.11,2.74-0.57,2.74-0.57s-0.8-1.72,0-2.06
		s3.2-1.03,3.89-1.03c0.69,0,2.97,1.37,2.97,1.37s1.49,2.86,1.83,3.2c0.34,0.34,0.69,2.4,1.6,2.52c0.91,0.11,4.12-0.11,4.46,0.11
		c0.34,0.23,1.26,2.74,1.37,3.43c0.11,0.69,0.91,2.06,1.83,2.29c0.91,0.23,3.54-0.57,4-0.91c0.46-0.34,3.2,1.03,3.66,1.14
		c0.46,0.11,4.12,0.34,4.57-0.23c0.46-0.57,1.83-3.2,1.83-3.2s2.17-1.83,2.17-2.52s-0.34-3.43,0.11-3.77s5.72-0.69,5.72-0.69
		l3.43,2.63c0,0,0.57,3.89,0.8,4.57c0.23,0.69-0.57,2.29,0.46,2.63c1.03,0.34,3.2,0.23,3.2,0.23l4.12-3.32l13.03-8.8l6.75-5.37
		c0,0,3.77-1.26,3.77-1.72s-0.46-6.97-0.46-6.97l0.69-2.17c0,0,2.52-0.8,2.97-1.72c0.46-0.91,0.57-2.52,0.57-3.43
		c0-0.91-1.6-4.12-2.06-4.69c-0.46-0.57-2.97-2.06-3.2-2.63c-0.23-0.57-1.83-5.37-1.6-5.83c0.23-0.46,0.69-4.8,0.69-4.8
		s2.29-3.32,2.29-3.66c0-0.34-0.11-3.54-0.46-3.89s-2.17-1.72-2.29-2.86c-0.11-1.14-2.52-4.92-2.52-4.92s-1.72-4.69-2.17-5.15
		c-0.46-0.46-3.32-4.92-2.97-5.37s0.57-3.2,0.57-3.2l2.97-1.6c0,0,1.26-1.49,1.03-2.06c-0.23-0.57-0.91-3.77-1.26-4.12
		c-0.34-0.34-3.2-1.49-3.2-1.49s-3.43,1.37-3.89,1.49c-0.46,0.11-3.2,0.91-3.2,0.91l-3.54,2.29l-6.52,0.23l-4.57-1.6l0.23-2.52
		l1.37-4.12l-2.52-1.83c0,0-4-0.34-4.46-0.34s-6.29-0.57-6.29-0.57l-2.17-0.69l-0.8-3.66c0,0-1.94-2.29-2.29-2.63
		s-1.83-2.74-1.83-2.74L688,594.6c0,0-3.89-0.8-4.23-0.91c-0.34-0.11-3.66,0.57-3.66,0.57l-2.29,3.2l-3.54,0.69l-2.74-2.29
		l-5.37-0.34l-1.49-1.72c0,0-0.69-4.23-0.46-4.69c0.23-0.46-0.46-3.2-0.8-3.32c-0.34-0.11-4.92-0.8-4.92-0.8l-6.29,1.03l-3.2-1.14
		l-2.86-2.63l-2.74-3.2l-4.57-0.57l-3.89-2.4l-3.66-0.57l-0.8-2.52l-5.26-1.03l-4.69-2.63l-2.52,2.06l-0.57,3.2l-2.86,2.17
		l-3.66,2.06l-2.63,3.89c0,0-6.75,0.57-7.09,0.57s-6.86-0.8-6.86-0.8s-0.8-2.86-0.8-3.2s-2.74-2.29-2.74-2.29l0.91-1.83l-2.17-2.29
		l-6.52-0.34c0,0-3.54-2.74-3.43-3.09c0.11-0.34-0.34-4.34-0.46-4.69s-2.4-1.72-2.4-1.72l-4,1.83l-0.11,4.23
		c0,0-2.29,1.49-2.63,1.37s-4.12-1.37-4.23-1.72c-0.11-0.34-3.77-3.09-3.77-3.09l-6.63-5.83l-4.46-0.69c0,0-5.37-5.49-5.72-5.83
		s-3.43-5.03-3.43-5.03l-0.46-3.2l-2.63-1.6c0,0-2.86-1.6-3.32-2.06C534.9,540.98,531.43,540.16,531.43,540.16z"
        />
        <text
          transform="matrix(1 0 0 1 503.9672 646.6009)"
          class="tik_map_region_text"
        >
          <tspan x="0" y="0" class="font-light font-larsseit">
            ANHALT - DESSAU -
          </tspan>
          <tspan x="22.93" y="18.94" class="font-light font-larsseit">
            WITTENBERG
          </tspan>
        </text>
      </g>
      <g
        id="Halle-Saale-Unstrut"
        @click="emitRegion('Halle-Saale-Unstrut')"
        :class="[
          selectedRegion == 'Halle-Saale-Unstrut' ? 'selectedRegion' : '',
        ]"
        class="cursor-pointer tik_map_region"
      >
        <path
          class="tik_map_st1"
          d="M445.31,702.31l1.72,0.86l1.29,2.15l3.01,0.86l1.93,3.01l3.65,2.79l7.3-0.64l3.01,1.93c0,0,3.44-1.5,4.08-1.5
		c0.64,0,12.89,0,12.89,0l0.64,4.08l-1.07,3.22l2.79,2.58l0.64,3.01l-3.01,5.15l2.15,2.15c0,0,3.44,1.93,4.08,1.93
		c0.64,0,7.3,0,7.3,0s13.96-0.86,14.6-0.86c0.64,0,3.22,1.07,3.22,1.07l1.07,2.36l-1.5,6.23l1.29,3.87l3.01,0.64l3.65,3.22
		l-0.23,1.92c0,0-0.95,5.72-1.23,6.26c-0.27,0.54,1.5,3.54,1.5,3.54l2.04,2.04l-0.68,4.08l-5.31,1.5c0,0-0.41,4.08-0.68,4.77
		c-0.27,0.68-0.95,0.41-2.18,0.41s-1.5,1.36-1.63,1.77s-0.27,3.4-0.27,4.22s1.09,6.13,1.09,6.13l3.13,0.95l2.59,1.09l0.82,6.13
		l-2.04,2.72l0.14,6.13c0,0,1.91,4.36,2.04,4.77s3,3.4,3.13,3.81c0.14,0.41,0.68,8.17,0.27,8.58c-0.41,0.41-0.14,3.4-0.27,3.81
		c-0.14,0.41-1.91,0.54-1.91,0.54s-4.22,2.72-4.36,3.13s-2.86,1.5-2.86,1.5l-0.68,2.59c0,0,2.18,4.49,2.45,4.9
		c0.27,0.41,3.4,4.08,3.4,4.08l-0.41,6.26c0,0,2.86,3.4,3.27,4.08c0.41,0.68,1.5,4.36,1.5,4.36l2.59,3.4v4.36l0.41,5.31l-3.4,6.81
		l-0.95,2.45l6.26,0.54l0.68,2.18c0,0,0.27,3.54,0.27,3.95c0,0.41-2.72,2.04-2.72,2.04l-0.14,4.36c0,0,0.82,2.86,1.09,3.27
		c0.27,0.41,4.49,0.41,4.49,0.41l3,2.31l0.14,3.4l-2.72,3l0.14,2.86l3.95,0.27l2.31,3.27l6.81-0.14c0,0,1.77,0.41,2.04,1.5
		c0.27,1.09,0.41,3.13-0.41,3.68c-0.82,0.54-2.45,1.23-2.45,1.23v3.95c0,0-3.13,0.68-3.27,1.09c-0.14,0.41,0.82,4.36,0.95,5.04
		c0.14,0.68-0.27,4.22-0.27,4.22l2.31,1.63h5.31c0,0,1.5,1.77,1.5,2.18c0,0.41,0.14,3.95,0,4.36c-0.14,0.41-2.04,1.91-2.59,2.18
		c-0.54,0.27-0.41,2.31-1.23,3.13c-0.82,0.82-1.77,2.18-2.31,2.59c-0.54,0.41-0.27,3-0.27,3l-3.4,3l-2.59,2.72l0.14,7.76l-2.04,3.81
		l-3.27-0.14l-1.77-2.45l-0.27-3.54l-3.13-1.36l-1.5-2.18c0,0-2.31,0.14-3,0.14s-2.31,2.45-2.31,2.45l-2.59,0.54
		c0,0-5.04-0.41-5.58-0.54c-0.54-0.14-5.17-3.13-5.17-3.13l-5.99,0.68l-3-0.68c0,0-7.08,0.14-7.76,0.27
		c-0.68,0.14-4.08,1.77-4.08,1.77l-2.31-1.23l-0.41-4.49c0,0-2.18-2.31-2.72-2.45s-3.27,0.27-3.81,0.27s-1.5-0.95-1.5-0.95
		l0.68-5.99l-1.23-2.86l-3.54-0.54l-1.91-2.31h-4.22l-3.27-0.82l-0.54-3.54c0,0-1.23-1.63-1.77-1.91s-3.13-0.14-3.13-0.14
		s-1.91-2.86-2.59-2.86s-4.49,0.27-5.04,0.41s-3.95,2.18-3.95,2.18l-7.9,0.82l-7.49,0.14l-5.58,0.95l-0.27-2.04l-2.31-3l-2.31-2.59
		l-5.85,0.14l-2.31-0.95c0,0,0.54-4.9,0.27-5.45c-0.27-0.54-0.68-3.68-1.09-3.95c-0.41-0.27-2.31-1.91-2.31-1.91
		s-3.13-0.27-3.68-0.41s-1.77-2.72-1.77-2.72s-2.45-0.14-3.68-0.14c-1.23,0-3.68,1.77-4.08,2.04s-2.86-0.14-2.86-0.14l-2.04-2.72
		c0,0-3.13,0-4.49-0.14c-1.36-0.14-1.91-0.95-2.31-0.95s-1.63,1.23-1.63,1.23l-3.54,1.09l-1.36,2.59l-5.04,0.14
		c0,0-5.17,0.41-5.58,0.68s-2.45,0.95-2.45,0.95s-1.91-1.77-2.31-1.91s-3.4-0.68-3.4-0.68s-0.82-6.13-1.09-6.54
		c-0.27-0.41-1.77-1.63-1.77-1.63l-0.82-5.17l2.86-2.59l2.31-2.31c0,0-0.27-3.4-0.54-4.36c-0.27-0.95-0.82-3.13-0.95-3.54
		c-0.14-0.41,0-3.95,0-4.36s-2.04-1.23-3.4-1.09s-1.91-0.14-2.45-0.27s-1.09-4.63-1.09-4.63l-2.04-0.27c0,0-3.81,0.41-4.22,0.54
		c-0.41,0.14-2.72,2.04-2.72,2.04h-3.54v-2.72l-2.18-3.27l-2.59-0.27l-0.95-3.27l0.68-3.95l2.59-0.95l5.99,1.09l2.31-1.91l1.23-3.27
		l1.63-1.09c0,0,1.23-1.5,1.23-2.18c0-0.68,0.54-3.54,0.54-3.54l2.72-0.95l0.27-4.22l2.18-1.36l0.95-3.27l-0.68-2.59l-2.04-2.45
		l-2.45-0.41l-2.18-1.5l-0.41-2.18l-2.72-1.23l-0.54-2.72l-1.91-0.54l-0.4-1.55l2.58-4.08l2.15-5.37l3.22-0.21l1.93-3.44l2.36-0.43
		l0.21-3.87l2.79-1.07l0.43-1.93l2.58-1.93l0.43-3.65l2.36-2.36l3.87-1.07l0.64-4.51l2.79-1.07l2.36-3.01h6.01l3.87-0.64l2.36-2.15
		l1.29-1.5l2.79-2.36l5.37,0.43l3.01,2.36l3.87-0.21c0,0,1.93,6.01,2.15,6.66c0.21,0.64,3.44,2.36,3.44,2.36l4.94-1.72l3.01-1.5
		l2.15-4.3l4.3-0.43c0,0,1.72-3.01,1.72-3.65c0-0.64,0-4.3-0.64-4.94c-0.64-0.64-5.15-4.3-5.8-4.94c-0.64-0.64-5.8-4.51-5.8-4.51
		l-2.58-3.87l-2.36-1.07l1.93-3.87l2.15-4.51l0.64-3.01l-2.36-3.01l-1.72-3.22l-3.87-1.5v-2.58l2.58-1.07l1.5-5.15l2.15-1.5
		l2.36-0.86l0.64-4.3l1.5-2.15l3.22-0.21l0.43-1.5v-2.79l-1.93-1.72l-0.14-0.72l0.57-5.44l2.29-1.43l0.29-2.29l4.01-0.29l4.3,3.15
		c0,0,2.29-2,3.15-2.29c0.86-0.29,3.15-0.57,3.15-0.57l-1.15-3.72l4.3,0.29l3.44,2.58l1.43-8.59l0.63-1.27L445.31,702.31z"
        />
        <text
          transform="matrix(1 0 0 1 425.3148 831.6536)"
          class="tik_map_region_text"
        >
          <tspan x="0" y="0" class="font-light font-larsseit">HALLE -</tspan>
          <tspan x="-0.4" y="18.94" class="font-light font-larsseit">
            SAALE -
          </tspan>
          <tspan x="-7.32" y="37.88" class="font-light font-larsseit">
            UNSTRUT
          </tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import { ref, watch } from "vue";
export default {
  name: "TikMap",
  props: ["isProgramOpen"],

  setup(props, { emit }) {
    const selectedRegion = ref();

    watch(
      () => props.isProgramOpen,
      (newValue) => {
        if (newValue === false) {
          selectedRegion.value = "";
        }
      }
    );

    const emitRegion = (region) => {
      selectedRegion.value = region;
      emit("clickOnRegion", region);
    };
    return {
      selectedRegion,
      emitRegion,
    };
  },
};
</script>

<style type="text/css">
.selectedRegion > path {
  fill: #f6e82e;
  stroke: #343434;
}

.selectedRegion > text {
  fill: #343434;
}

.tik_map_region:hover > path {
  fill: #f6e82e;
  stroke: #343434;
}

.tik_map_region_text {
  fill: #fff;
  font-size: 15.7838px;
}

.tik_map_region:hover > .tik_map_region_text {
  fill: #343434;
}

.tik_map_st1 {
  fill: #343434;
  stroke: #fff;
  stroke-width: 0.45;
  stroke-miterlimit: 10;
}

.tik_map_st3 {
  fill: #343434;
  stroke: #fff;
  stroke-width: 0.45;
  stroke-miterlimit: 10;
}
.tik_map_st5 {
  fill: #343434;
  stroke: #fff;
  stroke-width: 0.45;
  stroke-miterlimit: 10;
}
.tik_map_st6 {
  fill: #343434;
  stroke: #fff;
  stroke-width: 0.45;
  stroke-miterlimit: 10;
}
</style>
