<template>
  <div class="relative px-16">
    <div class="fixed z-10 p-1 left-4 top-20">
      <router-link to="/"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          aria-hidden="true"
          class="bg-gray-800 bg-opacity-20 rounded-3xl h-8 text-lg text-white hover:bg-gray-600"
        >
          <path
            fill-rule="evenodd"
            d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>
    </div>

    <div
      class="container mx-auto text-white max-w-[1000px] py-20 text-start text-white font-light font-larsseit"
    >
      <h1 style="font-size: 36px">Impressum</h1>
      <p style="font-size: 16px">
        Unternehmensangaben laut &sect;6 des Teledienstegesetzes (TDG):<br /><br />Ferropolis
        Stiftung<br />Industriekultur gGmbH<br />Ferropolisstrasse 1<br />06773
        Gr&auml;fenhainichen<br />Tel. 034953 - 35 120<br />Fax 034953 - 35
        123<br /><br />Gesch&auml;ftsf&uuml;hrer: Thies Schr&ouml;der &amp;
        Janine Scharf<br />Kontakt: info@ferropolis.de
      </p>
      <p>
        <span style="font-size: 16px">&nbsp;</span>
      </p>
      <h4 style="font-size: 16px">HAFTUNGSAUSSCHLUSS / DISCLAIMER</h4>
      <p>
        <span style="font-size: 16px">1. Inhalt des Onlineangebotes</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Die FERROPOLIS GmbH &uuml;bernimmt keinerlei Gew&auml;hr f&uuml;r die
          Aktualit&auml;t, Korrektheit, Vollst&auml;ndigkeit oder Qualit&auml;t
          der bereitgestellten Informationen. Haftungsanspr&uuml;che gegen die
          FERROPOLIS GmbH, welche sich auf Sch&auml;den materieller oder
          ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der
          dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollst&auml;ndiger Informationen verursacht wurden, sind
          grunds&auml;tzlich ausgeschlossen, sofern seitens der FERROPOLIS GmbH
          kein nachweislich vors&auml;tzliches oder grob fahrl&auml;ssiges
          Verschulden vorliegt. Alle Angebote sind freibleibend und
          unverbindlich. Die FERROPOLIS GmbH beh&auml;lt es sich
          ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu
          l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder
          endg&uuml;ltig einzustellen.</span
        >
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <span style="font-size: 16px">2. Verweise und Links</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Bei direkten oder indirekten Verweisen auf fremde Internetseiten
          (&quot;Links&quot;), die au&szlig;erhalb des Verantwortungsbereiches
          von der FERROPOLIS GmbH liegen, w&uuml;rde eine Haftungsverpflichtung
          ausschlie&szlig;lich in dem Fall in Kraft treten, in dem die
          FERROPOLIS GmbH von den Inhalten Kenntnis hat und es dem Betreiber des
          Online-Angebotes technisch m&ouml;glich und zumutbar w&auml;re, die
          Nutzung im Falle rechtswidriger Inhalte zu verhindern. Die FERROPOLIS
          GmbH erkl&auml;rt hiermit ausdr&uuml;cklich, dass zum Zeitpunkt der
          Linksetzung die entsprechenden verlinkten Seiten frei von illegalen
          Inhalten waren. Auf die aktuelle und zuk&uuml;nftige Gestaltung, die
          Inhalte oder die Urheberschaft der gelinkten/verkn&uuml;pften Seiten
          hat die FERROPOLIS GmbH keinerlei Einfluss. Deshalb distanziert sich
          die FERROPOLIS GmbH hiermit ausdr&uuml;cklich von allen Inhalten aller
          gelinkten/verkn&uuml;pften Seiten, die nach der Linksetzung
          ver&auml;ndert wurden. Diese Festlegung gilt f&uuml;r alle innerhalb
          des eigenen Internetangebotes gesetzten Links und Verweise sowie
          f&uuml;r Fremdeintr&auml;ge in von der FERROPOLIS GmbH eingerichteten
          G&auml;steb&uuml;chern, Diskussionsforen und Mailinglisten. F&uuml;r
          illegale, fehlerhafte oder unvollst&auml;ndige Inhalte und
          insbesondere f&uuml;r Sch&auml;den, die aus der Nutzung oder
          Nichtnutzung solcherart dargebotener Informationen entstehen, haftet
          allein der Anbieter der Seite, auf welche verwiesen wurde, nicht
          derjenige, der &uuml;ber Links auf die jeweilige Ver&ouml;ffentlichung
          lediglich verweist.</span
        >
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <span style="font-size: 16px">3. Urheber- und Kennzeichenrecht</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Die FERROPOLIS GmbH ist bestrebt, in allen Publikationen die
          Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen
          und Texte zu beachten, von ihr selbst erstellte Grafiken,
          Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie
          Grafiken, Tondokumente, Videosequenzen und Texte zur&uuml;ckzugreifen.
          Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte
          gesch&uuml;tzten Marken- und Warenzeichen unterliegen
          uneingeschr&auml;nkt den Bestimmungen des jeweils g&uuml;ltigen
          Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
          Eigent&uuml;mer. Allein aufgrund der blo&szlig;en Nennung ist nicht
          der Schlu&szlig; zu ziehen, dass Markenzeichen nicht durch Rechte
          Dritter gesch&uuml;tzt sind! Das Copyright f&uuml;r
          ver&ouml;ffentlichte, von der FERROPOLIS GmbH selbst erstellte Objekte
          bleibt auch ohne ausdr&uuml;ckliche Wiederholung allein bei der
          FERROPOLIS GmbH. Eine Vervielf&auml;ltigung oder Verwendung solcher
          Grafiken, Tondokumente, Videosequenzen und Texte in anderen
          elektronischen oder gedruckten Publikationen ist ohne
          ausdr&uuml;ckliche Zustimmung der FERROPOLIS GmbH nicht
          gestattet.</span
        >
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <span style="font-size: 16px">4. Datenschutz</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Sofern innerhalb des Internetangebotes die M&ouml;glichkeit zur
          Eingabe pers&ouml;nlicher oder gesch&auml;ftlicher Daten
          (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe
          dieser Daten seitens des Nutzers auf ausdr&uuml;cklich freiwilliger
          Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist
          - soweit technisch m&ouml;glich und zumutbar - auch ohne Angabe
          solcher Daten bzw. unter Angabe anonymisierter Daten oder eines
          Pseudonyms gestattet.</span
        >
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <span style="font-size: 16px"
          >N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz
          finden Sie unter http://www.google.com/analytics/terms/de.html bzw.
          unter&nbsp;https://www.google.de/intl/de/policies/. Wir weisen Sie
          darauf hin, dass auf dieser Website Google Analytics um den Code
          &bdquo;anonymizeIp&ldquo; erweitert wurde, um eine anonymisierte
          Erfassung von IP-Adressen (sog. IP-Masking) zu
          gew&auml;hrleisten.</span
        >
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <span style="font-size: 16px"
          >5. Rechtswirksamkeit dieses Haftungsausschlusses&nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollst&auml;ndig entsprechen sollten,
          bleiben die &uuml;brigen Teile des Dokumentes in ihrem Inhalt und
          ihrer G&uuml;ltigkeit davon unber&uuml;hrt.</span
        >
      </p>
    </div>

    <div id="footer" class="absolute bottom-5 left-5 text-white">
      <router-link to="/datenschutz" class="text-white hover:text-t-green"
        >Datenschutz</router-link
      >
      |
      <router-link to="/impressum" class="text-white hover:text-t-green"
        >Impressum</router-link
      >
    </div>
  </div>
</template>
