<template>
  <div class="relative px-16">
    <div class="fixed z-10 p-1 left-4 top-20">
      <router-link to="/"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          aria-hidden="true"
          class="bg-gray-800 bg-opacity-20 rounded-3xl h-8 text-lg text-white hover:bg-gray-600"
        >
          <path
            fill-rule="evenodd"
            d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>
    </div>

    <div
      class="container mx-auto text-white max-w-[1000px] py-20 text-start text-white font-light font-larsseit"
    >
      <h1 style="font-size: 36px">Datenschutz</h1>
      <p>
        <span style="font-size: 16px">Datenschutzerkl&auml;rung</span>
      </p>
      <p>
        <br style="font-size: 16px" />
      </p>
      <p>
        <span style="font-size: 16px"
          >1. Diese Datenschutz-Information gilt f&uuml;r die Datenverarbeitung
          durch:</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Verantwortlicher: Thies Schr&ouml;der, Gesch&auml;ftsf&uuml;hrer,
          FERROPOLIS GmbH, Ferropolisstra&szlig;e 1, 06773
          Gr&auml;fenhainichen</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die betriebliche Datenschutzbeauftragte der FERROPOLIS GmbH ist
          &nbsp;unter der o.g. Anschrift, zu Hd. Frau Tschernich-Weiske,
          beziehungsweise unter &nbsp;info@ferropolis.de erreichbar.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >2. Erhebung und Speicherung personenbezogener Daten sowie Art und
          Zweck von deren Verwendung</span
        >
      </p>
      <p>
        <span style="font-size: 16px">a) Beim Besuch der Website</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Beim Aufrufen unserer Website www.industriekultur-sachsen-anhalt.de
          werden durch den auf Ihrem Endger&auml;t zum Einsatz kommenden Browser
          automatisch Informationen an den Server unserer Website gesendet.
          Diese Informationen werden tempor&auml;r in einem sog. Logfile
          gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun
          erfasst und bis zur automatisierten L&ouml;schung gespeichert:</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;IP-Adresse des anfragenden Rechners,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Datum und Uhrzeit des Zugriffs,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Name und URL der abgerufenen Datei,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Website, von der aus der Zugriff erfolgt
          (Referrer-URL),</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;verwendeter Browser und ggf. das Betriebssystem Ihres
          Rechners sowie der Name Ihres Access-Providers.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die genannten Daten werden durch uns zu folgenden Zwecken
          verarbeitet:</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Gew&auml;hrleistung eines reibungslosen
          Verbindungsaufbaus der Website,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Gew&auml;hrleistung einer komfortablen Nutzung unserer
          Website,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Auswertung der Systemsicherheit und -stabilit&auml;t
          sowie</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;zu weiteren administrativen Zwecken.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die Rechtsgrundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1
          S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben
          aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir
          die erhobenen Daten zu dem Zweck, R&uuml;ckschl&uuml;sse auf Ihre
          Person zu ziehen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Dar&uuml;ber hinaus setzen wir beim Besuch unserer Website Cookies
          sowie Analysedienste ein. N&auml;here Erl&auml;uterungen dazu erhalten
          Sie unter den Ziff. 4 und 5 dieser Datenschutzerkl&auml;rung.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >b) Bei Anmeldung f&uuml;r unseren Newsletter</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdr&uuml;cklich
          eingewilligt haben, verwenden wir Ihre E-Mail-Adresse daf&uuml;r,
          Ihnen regelm&auml;&szlig;ig unseren Newsletter zu &uuml;bersenden.
          F&uuml;r den Empfang des Newsletters ist die Angabe einer
          E-Mail-Adresse ausreichend.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die Abmeldung ist jederzeit m&ouml;glich, zum Beispiel &uuml;ber
          einen Link am Ende eines jeden Newsletters. Alternativ k&ouml;nnen Sie
          Ihren Abmeldewunsch gerne auch jederzeit an info@ferropolis.de per
          E-Mail senden.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >c) Bei Nutzung unseres Kontaktformulars</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Bei Fragen jeglicher Art bieten wir Ihnen die M&ouml;glichkeit, mit
          uns &uuml;ber ein auf der Website bereitgestelltes Formular Kontakt
          aufzunehmen. Dabei ist die Angabe einer g&uuml;ltigen E-Mail-Adresse
          erforderlich, damit wir wissen, von wem die Anfrage stammt und um
          diese beantworten zu k&ouml;nnen. Weitere Angaben k&ouml;nnen
          freiwillig get&auml;tigt werden.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
          nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
          erteilten Einwilligung.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die f&uuml;r die Benutzung des Kontaktformulars von uns erhobenen
          personenbezogenen Daten werden nach Erledigung der von Ihnen
          gestellten Anfrage automatisch gel&ouml;scht.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">3. Weitergabe von Daten</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Eine &Uuml;bermittlung Ihrer pers&ouml;nlichen Daten an Dritte zu
          anderen als den im Folgenden aufgef&uuml;hrten Zwecken findet nicht
          statt.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Wir geben Ihre pers&ouml;nlichen Daten nur an Dritte weiter,
          wenn:</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
          ausdr&uuml;ckliche Einwilligung dazu</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; erteilt haben,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
          Geltendmachung,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen erforderlich ist und kein</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; Grund zur Annahme besteht, dass Sie ein
          &uuml;berwiegendes schutzw&uuml;rdiges</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; Interesse an der Nichtweitergabe Ihrer Daten
          haben,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;f&uuml;r den Fall, dass f&uuml;r die Weitergabe nach Art.
          6 Abs. 1 S. 1 lit. c DSGVO eine</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp;gesetzliche Verpflichtung besteht, sowie</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;dies gesetzlich zul&auml;ssig und nach Art. 6 Abs. 1 S. 1
          lit. b DSGVO f&uuml;r die</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp;Abwicklung von Vertragsverh&auml;ltnissen mit
          Ihnen erforderlich ist.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">4. Cookies</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um
          kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem
          Endger&auml;t (Laptop, Tablet, Smartphone o.&auml;.) gespeichert
          werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem
          Endger&auml;t keinen Schaden an, enthalten keine Viren, Trojaner oder
          sonstige Schadsoftware.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >In dem Cookie werden Informationen abgelegt, die sich jeweils im
          Zusammenhang mit dem spezifisch eingesetzten Endger&auml;t ergeben.
          Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von
          Ihrer Identit&auml;t erhalten.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres
          Angebots f&uuml;r Sie angenehmer zu gestalten. So setzen wir
          sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne
          Seiten unserer Website bereits besucht haben. Diese werden nach
          Verlassen unserer Seite automatisch gel&ouml;scht.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die durch Cookies verarbeiteten Daten sind f&uuml;r die genannten
          Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter
          nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die meisten Browser akzeptieren Cookies automatisch. Sie k&ouml;nnen
          Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem
          Computer gespeichert werden oder stets ein Hinweis erscheint, bevor
          ein neuer Cookie angelegt wird. Die vollst&auml;ndige Deaktivierung
          von Cookies kann jedoch dazu f&uuml;hren, dass Sie nicht alle
          Funktionen unserer Website nutzen k&ouml;nnen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">5. Analyse-Tools</span>
      </p>
      <p>
        <span style="font-size: 16px">Tracking-Tools</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Die im Folgenden aufgef&uuml;hrten und von uns eingesetzten
          Tracking-Ma&szlig;nahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1
          lit. f DSGVO durchgef&uuml;hrt. Mit den zum Einsatz kommenden
          Tracking-Ma&szlig;nahmen wollen wir eine bedarfsgerechte Gestaltung
          und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum
          anderen setzen wir die Tracking-Ma&szlig;nahmen ein, um die Nutzung
          unserer Webseite statistisch zu erfassen und zum Zwecke der
          Optimierung unseres Angebotes f&uuml;r Sie auszuwerten. Diese
          Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift
          anzusehen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus
          dem entsprechenden Tracking-Tool zu entnehmen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">Google Analytics</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
          Optimierung unserer Seiten nutzen wir Google Analytics, ein
          Webanalysedienst der Google Inc.
          (https://www.google.de/intl/de/about/) (1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA; im Folgenden &bdquo;Google&ldquo;). In
          diesem Zusammenhang werden pseudoanonymisierte Nutzungsprofile
          erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den
          Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website
          wie</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Browser-Typ/-Version,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;verwendetes Betriebssystem,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Referrer-URL (die zuvor besuchte Seite),</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Hostname des zugreifenden Rechners (IP-Adresse),</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;Uhrzeit der Serveranfrage,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >werden an einen Server von Google in den USA &uuml;bertragen und dort
          gespeichert. Die Informationen werden verwendet, um die Nutzung der
          Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten
          zusammenzustellen und um weitere mit der Websitenutzung und der
          Internetnutzung verbundene Dienstleistungen zu Zwecken der
          Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten
          zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte
          &uuml;bertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit
          Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre
          IP-Adresse mit anderen Daten von Google zusammengef&uuml;hrt. Die
          IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht
          m&ouml;glich ist (IP-Masking).</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende
          Einstellung der Browser-Software verhindern; wir weisen jedoch darauf
          hin, dass in diesem Fall gegebenenfalls nicht s&auml;mtliche
          Funktionen dieser Website vollumf&auml;nglich genutzt werden
          k&ouml;nnen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das
          Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
          (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch
          Google verhindern, indem Sie ein Browser-Add-on herunterladen und
          installieren (https://tools.google.com/dlpage/gaoptout?hl=de).</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen
          Endger&auml;ten, k&ouml;nnen Sie die Erfassung durch Google Analytics
          zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein
          Opt-out-Cookie gesetzt, das die zuk&uuml;nftige Erfassung Ihrer Daten
          beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in
          diesem Browser und nur f&uuml;r unsere Website und wird auf Ihrem
          Ger&auml;t abgelegt. L&ouml;schen Sie die Cookies in diesem Browser,
          m&uuml;ssen Sie das Opt-out-Cookie erneut setzen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Weitere Informationen zum Datenschutz im Zusammenhang mit Google
          Analytics finden Sie etwa in der Google Analytics-Hilfe
          (https://support.google.com/analytics/answer/6004245?hl=de).</span
        >
      </p>
      <p>
        <span style="font-size: 16px">6. Social Media Plug-ins</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Wir setzen auf unserer Website auf Grundlage des Art. 6 Abs. 1 S. 1
          lit. f DSGVO Social Plug-ins der sozialen Netzwerke Facebook,
          Instagram und TripAdvisor ein, um unser Unternehmen hier&uuml;ber
          bekannter zu machen. Der dahinterstehende werbliche Zweck ist als
          berechtigtes Interesse im Sinne der DSGVO anzusehen. Die Verantwortung
          f&uuml;r den datenschutzkonformen Betrieb ist durch deren jeweiligen
          Anbieter zu gew&auml;hrleisten. Die Einbindung dieser Plug-ins durch
          uns erfolgt im Wege der sogenannten Zwei-Klick-Methode um Besucher
          unserer Webseite bestm&ouml;glich zu sch&uuml;tzen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">a) Facebook</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Auf unserer Website kommen Social-Media Plugins von Facebook zum
          Einsatz, um deren Nutzung pers&ouml;nlicher zu gestalten. Hierf&uuml;r
          nutzen wir den &bdquo;LIKE&ldquo; oder &bdquo;TEILEN&ldquo;-Button. Es
          handelt sich dabei um ein Angebot von Facebook.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches
          Plugin enth&auml;lt, baut Ihr Browser eine direkte Verbindung mit den
          Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook
          direkt an Ihren Browser &uuml;bermittelt und von diesem in die
          Webseite eingebunden.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Durch die Einbindung der Plugins erh&auml;lt Facebook die
          Information, dass Ihr Browser die entsprechende Seite unseres
          Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Konto
          besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese
          Information (einschlie&szlig;lich Ihrer IP-Adresse) wird von Ihrem
          Browser direkt an einen Server von Facebook in den USA
          &uuml;bermittelt und dort gespeichert.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer
          Website Ihrem Facebook-Konto direkt zuordnen. Wenn Sie mit den Plugins
          interagieren, zum Beispiel den &bdquo;LIKE&ldquo; oder
          &bdquo;TEILEN&ldquo;-Button bet&auml;tigen, wird die entsprechende
          Information ebenfalls direkt an einen Server von Facebook
          &uuml;bermittelt und dort gespeichert. Die Informationen werden zudem
          auf Facebook ver&ouml;ffentlicht und Ihren Facebook-Freunden
          angezeigt.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Facebook kann diese Informationen zum Zwecke der Werbung,
          Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten
          benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und
          Beziehungsprofile erstellt, z. B. um Ihre Nutzung unserer Website im
          Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen
          auszuwerten, andere Facebook-Nutzer &uuml;ber Ihre Aktivit&auml;ten
          auf unserer Website zu informieren und um weitere mit der Nutzung von
          Facebook verbundene Dienstleistungen zu erbringen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Wenn Sie nicht m&ouml;chten, dass Facebook die &uuml;ber unseren
          Webauftritt gesammelten Daten Ihrem Facebook-Konto zuordnet,
          m&uuml;ssen Sie sich vor Ihrem Besuch unserer Website bei Facebook
          ausloggen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
          Nutzung der Daten durch Facebook sowie Ihre diesbez&uuml;glichen
          Rechte und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer
          Privatsph&auml;re entnehmen Sie bitte den Datenschutzhinweisen
          (https://www.facebook.com/about/privacy/) von Facebook.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">b) Instagram</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Auf unserer Website werden auch sogenannte Social Plugins
          (&bdquo;Plugins&ldquo;) von Instagram verwendet, das von der Instagram
          LLC.,&nbsp;1601 Willow Road, Menlo Park, CA 94025,
          USA&nbsp;(&bdquo;Instagram&ldquo;) betrieben wird.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die Plugins sind mit einem Instagram-Logo beispielsweise in Form
          einer &bdquo;Instagram-Kamera&ldquo; gekennzeichnet.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches
          Plugin enth&auml;lt, stellt Ihr Browser eine direkte Verbindung zu den
          Servern von Instagram her. Der Inhalt des Plugins wird von Instagram
          direkt an Ihren Browser &uuml;bermittelt und in die Seite eingebunden.
          Durch diese Einbindung erh&auml;lt Instagram die Information, dass Ihr
          Browser die entsprechende Seite unseres Webauftritts aufgerufen hat,
          auch wenn Sie kein Instagram-Profil besitzen oder gerade nicht bei
          Instagram eingeloggt sind.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Diese Information (einschlie&szlig;lich Ihrer IP-Adresse) wird von
          Ihrem Browser direkt an einen Server von Instagram in die USA
          &uuml;bermittelt und dort gespeichert. Sind Sie bei Instagram
          eingeloggt, kann Instagram den Besuch unserer Website Ihrem
          Instagram-Account unmittelbar zuordnen. Wenn Sie mit den Plugins
          interagieren, zum Beispiel das &bdquo;Instagram&ldquo;-Button
          bet&auml;tigen, wird diese Information ebenfalls direkt an einen
          Server von Instagram &uuml;bermittelt und dort gespeichert.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Die Informationen werden au&szlig;erdem auf Ihrem Instagram-Account
          ver&ouml;ffentlicht und dort Ihren Kontakten angezeigt.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Wenn Sie nicht m&ouml;chten, dass Instagram die &uuml;ber unseren
          Webauftritt gesammelten Daten unmittelbar Ihrem Instagram-Account
          zuordnet, m&uuml;ssen Sie sich vor Ihrem Besuch unserer Website bei
          Instagram ausloggen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Weitere Informationen hierzu Sie in der Datenschutzerkl&auml;rung
          (https://help.instagram.com/155833707900388) von Instagram.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">c) Tripadvisor</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Diese Seite nutzt &uuml;ber eine API das Tripadvisor Widget. Anbieter
          ist die TripAdvisor LLC, 400 1st Avenue, Needham, MA 02494 USA. Beim
          Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Skripte
          in ihren Browsercache, um das Preisinformation Widget korrekt
          anzuzeigen.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern der TripAdvisor LLC aufnehmen. Hierdurch erlangt die
          TripAdvisor LLC Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse
          unsere Website aufgerufen wurde. Wir weisen darauf hin, dass wir als
          Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten
          Daten sowie deren Nutzung durch die TripAdvisor LLC erhalten. Die
          Nutzung von Tripadvisor erfolgt im Interesse der Information unserer
          G&auml;ste. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
          Abs. 1 lit. f DSGVO dar.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Weitere Informationen zu Tripadvisor finden Sie unter
          https://www.tripadvisor.de/</span
        >
      </p>
      <p>
        <span style="font-size: 16px">7. Betroffenenrechte</span>
      </p>
      <p>
        <span style="font-size: 16px">Sie haben das Recht:</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          &nbsp;gem&auml;&szlig; Art. 15 DSGVO Auskunft &uuml;ber Ihre von uns
          verarbeiteten personenbezogenen Daten zu verlangen. &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;Insbesondere k&ouml;nnen Sie Auskunft &uuml;ber die
          Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;die Kategorien
          von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt
          wurden oder werden, die geplante</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
          L&ouml;schung, Einschr&auml;nkung der Verarbeitung oder</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer
          Daten,sofern diese nicht bei uns erhoben</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          wurden, sowie &uuml;ber das Bestehen einer automatisierten
          Entscheidungsfindung einschlie&szlig;lich Profiling und ggf.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          aussagekr&auml;ftigen Informationen zu deren Einzelheiten
          verlangen;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          gem&auml;&szlig; Art. 16 DSGVO unverz&uuml;glich die Berichtigung
          unrichtiger oder Vervollst&auml;ndigung Ihrer bei uns
          gespeicherten</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          personenbezogenen Daten zu verlangen;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          gem&auml;&szlig; Art. 17 DSGVO die L&ouml;schung Ihrer bei uns
          gespeicherten personenbezogenen Daten zu verlangen, soweit</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; nicht die
          Verarbeitung zur Aus&uuml;bung des Rechts auf freie
          Meinungs&auml;u&szlig;erung und Information, zur&nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des
          &ouml;ffentlichen</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Interesses
          oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Rechtsanspr&uuml;chen erforderlich ist;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          gem&auml;&szlig; Art. 18 DSGVO die Einschr&auml;nkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen, soweit die</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Richtigkeit
          der Daten von Ihnen bestritten wird, die Verarbeitung
          unrechtm&auml;&szlig;ig ist, Sie aber deren L&ouml;schung
          ablehnen</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; und wir die
          Daten nicht mehr ben&ouml;tigen, Sie jedoch diese zur Geltendmachung,
          Aus&uuml;bung oder Verteidigung von&nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Rechtsanspr&uuml;chen ben&ouml;tigen oder Sie gem&auml;&szlig; Art. 21
          DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          gem&auml;&szlig; Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie
          uns bereitgestellt haben, in einem strukturierten,</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; g&auml;ngigen
          und maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung
          an einen anderen&nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Verantwortlichen zu verlangen;</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          gem&auml;&szlig; Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
          jederzeit gegen&uuml;ber uns zu widerrufen. Dies hat zur</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Folge,
          dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
          f&uuml;r die Zukunft nicht mehr fortf&uuml;hren d&uuml;rfen</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;und</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &bull; &nbsp;
          gem&auml;&szlig; Art. 77 DSGVO sich bei einer Aufsichtsbeh&ouml;rde zu
          beschweren. In der Regel k&ouml;nnen Sie sich hierf&uuml;r an
          die</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes oder
          Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</span
        >
      </p>
      <p>
        <span style="font-size: 16px">8. Widerspruchsrecht</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
          Interessen gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. f DSGVO
          verarbeitet werden, haben Sie das Recht, gem&auml;&szlig; Art. 21
          DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
          einzulegen, soweit daf&uuml;r Gr&uuml;nde vorliegen, die sich aus
          Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen
          Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
          Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns
          umgesetzt wird.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >M&ouml;chten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, gen&uuml;gt eine E-Mail an info@ferropolis.de</span
        >
      </p>
      <p>
        <span style="font-size: 16px">9. Datensicherheit</span>
      </p>
      <p>
        <span style="font-size: 16px"
          >Wir verwenden innerhalb des Website-Besuchs das verbreitete
          SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          h&ouml;chsten Verschl&uuml;sselungsstufe, die von Ihrem Browser
          unterst&uuml;tzt wird. In der Regel handelt es sich dabei um eine 256
          Bit Verschl&uuml;sselung. Falls Ihr Browser keine 256-Bit
          Verschl&uuml;sselung unterst&uuml;tzt, greifen wir stattdessen auf
          128-Bit v3 Technologie zur&uuml;ck. Ob eine einzelne Seite unseres
          Internetauftrittes verschl&uuml;sselt &uuml;bertragen wird, erkennen
          Sie an der geschlossenen Darstellung des Sch&uuml;ssel-
          beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres
          Browsers.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Wir bedienen uns im &Uuml;brigen geeigneter technischer und
          organisatorischer Sicherheitsma&szlig;nahmen, um Ihre Daten gegen
          zuf&auml;llige oder vors&auml;tzliche Manipulationen, teilweisen oder
          vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten
          Zugriff Dritter zu sch&uuml;tzen. Unsere Sicherheitsma&szlig;nahmen
          werden entsprechend der technologischen Entwicklung fortlaufend
          verbessert.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >&nbsp;10. Aktualit&auml;t und &Auml;nderung dieser
          Datenschutzerkl&auml;rung</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den
          Stand Mai 2018.</span
        >
      </p>
      <p>
        <span style="font-size: 16px"
          >Durch die Weiterentwicklung unserer Website und Angebote dar&uuml;ber
          oder aufgrund ge&auml;nderter gesetzlicher beziehungsweise
          beh&ouml;rdlicher Vorgaben kann es notwendig werden, diese
          Datenschutzerkl&auml;rung zu &auml;ndern. Die jeweils aktuelle
          Datenschutzerkl&auml;rung kann jederzeit auf der Website unter
          https://www.industriekultur-sachsen-anhalt.de/datenschutz von Ihnen
          abgerufen und ausgedruckt werden.</span
        >
      </p>
    </div>

    <div id="footer" class="absolute bottom-5 left-5 text-white">
      <router-link to="/datenschutz" class="text-white hover:text-t-green"
        >Datenschutz</router-link
      >
      |
      <router-link to="/impressum" class="text-white hover:text-t-green"
        >Impressum</router-link
      >
    </div>
  </div>
</template>
