<template>
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="149.84 19.61 521.44 554.4"
    style="enable-background: new 0 0 841.89 595.28"
    xml:space="preserve"
  >
    <g>
      <polyline
        class="tik_logo_st0"
        points="237.64,400.52 478.46,336.01 207.87,341.85 403.14,306.96 408.85,375.97 	"
      />
      <polyline
        class="tik_logo_st0"
        points="478.46,336.01 396.37,517.81 309.21,248.38 403.14,306.96 399.43,77.13 309.21,248.38 207.87,341.85 
		237.64,400.52 403.14,306.96 478.46,336.01 408.85,375.97 396.37,517.81 237.64,400.52 309.21,248.38 	"
      />
      <polyline
        class="tik_logo_st0"
        points="237.64,400.52 408.85,375.97 207.87,341.85 	"
      />
    </g>
    <g>
      <g>
        <path
          class="tik_logo_st1"
          d="M503.14,377.42l-0.46,2.38h-9.89l-4.29,24.44h-2.53l4.25-24.44h-9.89l0.42-2.38H503.14z"
        />
        <path
          class="tik_logo_st1"
          d="M513.37,377.42l6.28,26.82h-2.72l-1.84-7.78h-12.38l-4.48,7.78h-2.91L511,377.42H513.37z M511.65,380.91
			l-0.73,1.42l-6.9,11.72h10.65l-2.76-11.72L511.65,380.91z"
        />
        <path
          class="tik_logo_st1"
          d="M548.62,385h-2.87c-0.58-3.53-3.14-5.71-7.74-5.71c-3.56,0-6.59,1.42-9.12,4.21
			c-2.53,2.8-3.79,6.05-3.79,9.69c0,2.76,0.77,4.98,2.34,6.67c1.57,1.65,3.6,2.49,6.17,2.49c5.71,0,9.96-3.07,11.23-9.08h-8.81
			l0.42-2.41h11.61l-0.27,1.53c-1.42,7.7-6.4,12.34-14.21,12.34c-3.33,0-5.98-1.07-8.01-3.18c-2.03-2.15-3.03-4.94-3.03-8.35
			c0-2.76,0.65-5.36,1.99-7.85c1.34-2.49,3.18-4.52,5.59-6.09c2.41-1.57,5.1-2.34,7.97-2.34C543.94,376.92,547.74,379.99,548.62,385
			z"
        />
        <path
          class="tik_logo_st1"
          d="M572.6,377.42c3.6,0,6.44,1,8.51,2.95c2.11,1.95,3.14,4.64,3.14,8.01c0,4.48-1.46,8.24-4.41,11.3
			c-2.95,3.03-6.74,4.56-11.42,4.56h-9.69l4.71-26.82H572.6z M565.55,379.79l-3.87,22.07h6.93c3.72,0,6.78-1.3,9.27-3.87
			s3.75-5.79,3.75-9.58c0-5.48-3.29-8.62-9.16-8.62H565.55z"
        />
        <path
          class="tik_logo_st1"
          d="M605.74,377.42l-0.46,2.38H593.4l-1.65,9.42h10.5l-0.38,2.38h-10.5l-1.84,10.27h11.88l-0.38,2.38h-14.44
			l4.71-26.82H605.74z"
        />
        <path
          class="tik_logo_st1"
          d="M617.65,377.42c4.6,0,7.51,2.72,7.4,6.74c0,2.03-0.61,3.79-1.88,5.33c-1.26,1.49-2.87,2.53-4.87,3.07
			l4.37,11.69h-2.61l-4.25-11.3h-4.94l-1.95,11.3h-2.53l4.71-26.82H617.65z M613.2,379.79l-1.92,10.8h4.29
			c1.84,0,3.45-0.58,4.83-1.76c1.42-1.19,2.11-2.76,2.11-4.64c0.04-2.76-1.88-4.41-5.06-4.41H613.2z"
        />
        <path
          class="tik_logo_st1"
          d="M486.67,417.03v26.82h-6.63v-26.82H486.67z"
        />
        <path
          class="tik_logo_st1"
          d="M515.25,417.03v26.82h-6.63l-9.31-13.91l-1.03-1.88v15.79h-6.63v-26.82h6.63l9.27,14.06l1.07,1.92v-15.98
			H515.25z"
        />
        <path
          class="tik_logo_st1"
          d="M530.57,417.03c4.25,0,7.66,1.26,10.23,3.75s3.83,5.71,3.83,9.66s-1.26,7.2-3.83,9.69
			c-2.57,2.49-5.98,3.72-10.23,3.72h-10.35v-26.82H530.57z M526.85,423.2v14.48h3.72c4.71,0,7.16-2.53,7.16-7.24
			s-2.45-7.24-7.16-7.24H526.85z"
        />
        <path
          class="tik_logo_st1"
          d="M570.45,417.03v15.9c0,7.17-3.98,11.42-11.15,11.42s-11.19-4.25-11.19-11.42v-15.9h6.63v15.9
			c0,3.49,1.53,5.25,4.56,5.25c3.03,0,4.52-1.76,4.52-5.25v-15.9H570.45z"
        />
        <path
          class="tik_logo_st1"
          d="M592.36,424.84h-6.67c-0.12-1.57-1.07-2.49-2.45-2.49c-1.38,0-2.18,0.73-2.18,1.95c0,1.49,1,1.99,4.25,3.03
			c5.71,1.84,7.78,4.37,7.78,8.62c0,2.64-0.88,4.67-2.64,6.17c-1.72,1.49-3.95,2.22-6.67,2.22c-6.28,0-9.73-3.52-9.88-8.93h6.67
			c0.15,2.11,1.26,3.14,3.29,3.14c1.57,0,2.57-0.77,2.57-1.99c0-1.38-1.15-2.22-4.06-3.14c-5.33-1.65-7.97-4.1-7.97-8.51
			c0-2.53,0.8-4.56,2.45-6.09c1.65-1.53,3.87-2.3,6.59-2.3C588.8,416.53,592.06,419.37,592.36,424.84z"
        />
        <path
          class="tik_logo_st1"
          d="M617.61,417.03v6.17h-8.12v20.65h-6.63V423.2h-8.12v-6.17H617.61z"
        />
        <path
          class="tik_logo_st1"
          d="M629.56,417.03c3.03,0,5.4,0.8,7.2,2.45s2.72,3.79,2.72,6.44c0,3.18-1.61,5.75-4.37,7.24l5.52,10.69h-7.51
			l-4.75-9.42h-1.19v9.42h-6.63v-26.82H629.56z M627.19,423.2v5.44h2.38c2.11,0,3.14-0.92,3.14-2.72s-1.04-2.72-3.14-2.72H627.19z"
        />
        <path
          class="tik_logo_st1"
          d="M650.32,417.03v26.82h-6.63v-26.82H650.32z"
        />
        <path
          class="tik_logo_st1"
          d="M671.28,417.03v6.17h-9.35v3.83h7.93v6.17h-7.93v4.48h9.35v6.17H655.3v-26.82H671.28z"
        />
        <path
          class="tik_logo_st1"
          d="M502.53,456.64l-8.01,12.15l9.16,14.67h-7.85l-5.86-9.35l-3.29,4.44v4.9h-6.63v-26.82h6.63v12.41l7.85-12.41
			H502.53z"
        />
        <path
          class="tik_logo_st1"
          d="M528.58,456.64v15.9c0,7.17-3.98,11.42-11.15,11.42s-11.19-4.25-11.19-11.42v-15.9h6.63v15.9
			c0,3.49,1.53,5.25,4.56,5.25c3.03,0,4.52-1.76,4.52-5.25v-15.9H528.58z"
        />
        <path
          class="tik_logo_st1"
          d="M539.88,456.64v20.65h10.54v6.17h-17.17v-26.82H539.88z"
        />
        <path
          class="tik_logo_st1"
          d="M570.45,456.64v6.17h-8.12v20.65h-6.63v-20.65h-8.12v-6.17H570.45z"
        />
        <path
          class="tik_logo_st1"
          d="M595.43,456.64v15.9c0,7.17-3.98,11.42-11.15,11.42s-11.19-4.25-11.19-11.42v-15.9h6.63v15.9
			c0,3.49,1.53,5.25,4.56,5.25c3.03,0,4.52-1.76,4.52-5.25v-15.9H595.43z"
        />
        <path
          class="tik_logo_st1"
          d="M609.1,456.64c3.03,0,5.4,0.8,7.2,2.45s2.72,3.79,2.72,6.44c0,3.18-1.61,5.75-4.37,7.24l5.52,10.69h-7.51
			l-4.75-9.42h-1.19v9.42h-6.63v-26.82H609.1z M606.73,462.81v5.44h2.38c2.11,0,3.14-0.92,3.14-2.72s-1.04-2.72-3.14-2.72H606.73z"
        />
      </g>
      <g>
        <circle class="tik_logo_st2" cx="398.52" cy="74.51" r="5.84" />
        <circle class="tik_logo_st2" cx="308.3" cy="245.76" r="5.84" />
        <circle class="tik_logo_st2" cx="236.73" cy="397.9" r="5.84" />
        <circle class="tik_logo_st2" cx="206.96" cy="339.23" r="5.84" />
        <circle class="tik_logo_st2" cx="477.55" cy="333.39" r="5.84" />
        <circle class="tik_logo_st2" cx="402.23" cy="304.34" r="5.84" />
        <circle class="tik_logo_st2" cx="407.94" cy="373.35" r="5.84" />
        <circle class="tik_logo_st2" cx="395.46" cy="515.19" r="5.84" />
      </g>
    </g>
  </svg>
</template>

<style type="text/css">
.tik_logo_st0 {
  fill: none;
  stroke: #f6e82e;
  stroke-width: 1.4913;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.tik_logo_st1 {
  fill: #f6e82e;
}
.tik_logo_st2 {
  fill: #ffffff;
}
</style>
