<template>
  <div class="relative">
    <div class="fixed z-10">
      <div
        class="hidden md:flex mt-5 ml-5 justify-center flex-col items-center cursor-pointer"
      >
        <a href="https://industriekultur-sachsen-anhalt.de">
          <TikLogoN class="mb-5 h-[75px] mb-5 lg:h-[150px]"></TikLogoN>
        </a>
      </div>
    </div>
  </div>
  <div class="relative">
    <TourismusRegionen @click-on-region="hideFooter" />
    <div class="hidden md:flex absolute bottom-20">
      <div
        class="hidden md:flex mt-5 ml-5 justify-center flex-col items-center cursor-pointer"
      >
        <div class="w-full flex justify-center relative">
          <a
            href="https://kultur.sachsen-anhalt.de/kultur-entdecken/kulturland-entdecken/industriekultur#"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="../assets/lsa_logo_farbig.png" class="max-h-[50px]" />
          </a>
          <div
            class="absolute right-0 left-0 bottom-0 h-[55px] bg-white z-[-1]"
          ></div>
        </div>
        <a
          href="https://moderndenken.sachsen-anhalt.de/moderne-denker"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../assets/moderndenken_logo_weiss.png"
            class="max-h-[50px]"
          />
        </a>
        <a
          href="https://www.ferropolis.de/de/cms/_redaktionell/28/Industriekultur.html"
          target="_blank"
          rel="noopener noreferrer"
          class="mt-5"
        >
          <img src="../assets/nik_logo_weiss.png" class="max-h-[28px]" />
        </a>
      </div>
    </div>
    <div
      v-if="!isProgramOpen"
      id="footer"
      class="absolute bottom-5 left-5 text-white flex items-center"
    >
      <router-link to="/datenschutz" class="text-white hover:text-t-green mr-1"
        >Datenschutz</router-link
      >
      |
      <router-link to="/impressum" class="text-white hover:text-t-green ml-1"
        >Impressum</router-link
      >
    </div>
    <div
      v-if="!isProgramOpen"
      id="footer"
      class="absolute bottom-5 right-5 md:hidden text-white flex items-center"
    >
      <div class="flex">
        <a
          href="https://www.facebook.com/nik.industriekultur/"
          class="text-gray-400 hover:text-gray-500 ml-4"
        >
          <span class="sr-only">Facebook</span>
          <svg
            class="w-8 h-8"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clip-rule="evenodd"
            />
          </svg>
        </a>

        <a
          href="https://www.instagram.com/nik.industriekultur"
          class="text-gray-400 hover:text-gray-500 ml-4"
        >
          <span class="sr-only">Instagram</span>
          <svg
            class="w-8 h-8"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
    <div
      id="header"
      class="absolute hidden md:top-5 md:right-5 text-white md:flex items-center"
    >
      <div class="flex">
        <a
          href="https://www.facebook.com/nik.industriekultur/"
          class="text-gray-400 hover:text-gray-500 ml-4"
        >
          <span class="sr-only">Facebook</span>
          <svg
            class="w-8 h-8"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clip-rule="evenodd"
            />
          </svg>
        </a>

        <a
          href="https://www.instagram.com/nik.industriekultur"
          class="text-gray-400 hover:text-gray-500 ml-4"
        >
          <span class="sr-only">Instagram</span>
          <svg
            class="w-8 h-8"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TourismusRegionen from "../components/TourismusRegionen.vue";
import TikLogoN from "../components/TikLogoN.vue";
import { ref } from "vue";

export default {
  name: "HomePage",
  components: {
    TourismusRegionen,
    TikLogoN,
  },

  setup() {
    const isProgramOpen = ref(false);
    const hideFooter = (programOpen) => {
      isProgramOpen.value = programOpen;
    };
    return {
      hideFooter,
      isProgramOpen,
    };
  },
};
</script>
